"use client";

import { ReactNode } from "react";
import { StatsigProvider } from "statsig-react";

const StatsigClientProvider = ({ children }: { children: ReactNode }) => {
  return (
    <StatsigProvider
      user={{}}
      sdkKey={process.env.NEXT_PUBLIC_STATSIG_CLIENT_API_KEY || ""}
      waitForInitialization={false}
      options={{
        environment: {
          tier: process.env.NEXT_PUBLIC_STATSIG_ENVIROMENT_TIER,
        },
        localMode: !!process.env.NEXT_PUBLIC_STATSIG_LOCAL_MODE,
      }}
    >
      {children}
    </StatsigProvider>
  );
};

export default StatsigClientProvider;
