"use client";

import { datadogInit } from "./init-monitoring";
import { useTrackers } from "./use-trackers";

import { getSessionId, generateNewSession } from "analytics";

const ClientInitializer = () => {
  useTrackers();
  datadogInit();

  if (!getSessionId()) {
    generateNewSession();
  }

  return <></>;
};

export default ClientInitializer;
