"use client";
import { useEffect, useRef } from "react";
import mixpanel, { Mixpanel } from "mixpanel-browser";
import { usePathname } from "next/navigation";
import { mixpanelInit, trackPageView } from "analytics";

// Initializes the user and sets up pageview tracking
export const useTrackers = () => {
  const pathname = usePathname();
  const isMixpanelInitializedRef = useRef(false);

  const loaded = (mixpanel: Mixpanel) => {
    mixpanel.register({
      view_source: "just",
    });
  };

  //everything inside this useEffect is executed on the client side
  useEffect(() => {
    if (isMixpanelInitializedRef.current) return;

    mixpanelInit(
      mixpanel,
      process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string,
      process.env.NEXT_PUBLIC_MIXPANEL_API_HOST as string,
      process.env.NEXT_PUBLIC_MIXPANEL_DEBUG_MODE as string,
      loaded,
    );

    isMixpanelInitializedRef.current = true;
  }, []);

  // Listen for location changes
  useEffect(() => {
    // Get source parameter
    trackPageView(mixpanel, pathname);
  }, [pathname]);
};
