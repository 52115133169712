"use client";

import { datadogRum } from "@datadog/browser-rum";

import { getSessionId } from "analytics";

export const datadogInit = () => {
  const datadogInitialized = datadogRum.getInternalContext();

  if (!datadogInitialized) {
    datadogRum.init({
      applicationId: "3cf98afd-7440-435b-baf2-de8b9911a078",
      clientToken: "pub5929255ddf890d806987ea771b2073c0",
      site: "datadoghq.com",
      service: "just",
      env: process.env.NEXT_PUBLIC_ENV,
      version: process.env.NEXT_PUBLIC_VERSION || "-",
      sessionSampleRate:
        Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE) || 0,
      sessionReplaySampleRate:
        Number(process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE) || 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    datadogRum.setGlobalContextProperty("sessionId", getSessionId());
  }
};
