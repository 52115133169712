import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/packages/fastlane-www/src/app/globals.scss");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/node_modules/next/font/google/target.css?{\"path\":\"src/ui/fonts.ts\",\"import\":\"Lato\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"700\",\"900\"],\"subsets\":[\"latin\"]}],\"variableName\":\"lato\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/node_modules/next/font/google/target.css?{\"path\":\"src/ui/fonts.ts\",\"import\":\"Lora\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"lora\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/packages/fastlane-www/src/integrations/client-initializer.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/packages/fastlane-www/src/integrations/statsig.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/packages/fastlane-www/src/integrations/use-trackers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/packages/fastlane-www/src/locales/client.ts");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/packages/fastlane-www/src/ui/components/footer.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/newco-ui/newco-ui/packages/purple-rain/assets/fastlane-logotype-white.svg")