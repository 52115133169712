var u = /* @__PURE__ */ ((e) => (e.CLICK = "click", e.FORM_SUBMIT = "form_submit", e.MODULE_SERVED = "module_served", e.PAGEVIEW = "pageview", e.SIGN_UP = "sign_up", e.SESSION_END = "session_end", e.SESSION_START = "session_start", e))(u || {});
const w = "https://api-js.mixpanel.com", F = (e, t) => {
  t === "true" && e.disable();
}, N = (e, t, o = w, s = "", r = () => {
}) => {
  try {
    e.init(t, {
      // Send the mixpanel cookie over https
      secure_cookie: !0,
      // Only set debug mode if the flag is present
      debug: !!s,
      api_host: o,
      loaded: (d) => r(d)
    });
  } catch (d) {
    console.error("Mixpanel init error:", d);
  }
}, T = (e, t) => {
  e.people.set_once({ $name: t });
}, C = (e, t, o = {}) => {
  e.track("pageview", {
    path: t,
    ...o
  });
};
let c;
const p = new Uint8Array(16);
function I() {
  if (!c && (c = typeof crypto < "u" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto), !c))
    throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return c(p);
}
const n = [];
for (let e = 0; e < 256; ++e)
  n.push((e + 256).toString(16).slice(1));
function _(e, t = 0) {
  return n[e[t + 0]] + n[e[t + 1]] + n[e[t + 2]] + n[e[t + 3]] + "-" + n[e[t + 4]] + n[e[t + 5]] + "-" + n[e[t + 6]] + n[e[t + 7]] + "-" + n[e[t + 8]] + n[e[t + 9]] + "-" + n[e[t + 10]] + n[e[t + 11]] + n[e[t + 12]] + n[e[t + 13]] + n[e[t + 14]] + n[e[t + 15]];
}
const U = typeof crypto < "u" && crypto.randomUUID && crypto.randomUUID.bind(crypto), m = {
  randomUUID: U
};
function y(e, t, o) {
  if (m.randomUUID && !t && !e)
    return m.randomUUID();
  e = e || {};
  const s = e.random || (e.rng || I)();
  if (s[6] = s[6] & 15 | 64, s[8] = s[8] & 63 | 128, t) {
    o = o || 0;
    for (let r = 0; r < 16; ++r)
      t[o + r] = s[r];
    return t;
  }
  return _(s);
}
const h = () => y(), a = "session_id", g = "linked_session_ids", l = "session_timestamp", S = "new_session", i = typeof window < "u", D = () => h().replace(/-/g, "").substring(0, 25), v = () => i && window.localStorage.getItem(a), x = (e) => i && window.localStorage.setItem(a, e), L = () => i && window.localStorage.removeItem(a), O = () => i && window.localStorage.setItem(S, "true"), M = () => i && window.localStorage.getItem(S), j = () => i && window.localStorage.removeItem(S), P = () => {
  const e = `${Date.now()}`;
  i && window.localStorage.setItem(l, e);
}, $ = () => i && window.localStorage.getItem(l), G = () => i && window.localStorage.removeItem(l), k = () => {
  const e = D();
  return x(e), O(), e;
}, b = () => v() || k(), R = () => {
  const e = i && window.localStorage.getItem(g) || "[]";
  return JSON.parse(e);
}, H = (e) => {
  const t = R();
  if (!t.includes(e)) {
    const o = [...t, e];
    i && window.localStorage.setItem(
      g,
      JSON.stringify(o)
    );
  }
}, J = (e, t) => {
  const o = b(), s = new URL(e);
  return s.searchParams.set("utm_medium", "session_id"), s.searchParams.set("utm_content", o), t && s.searchParams.set("utm_source", t), s.toString();
}, K = () => {
  var s, r;
  if (!i)
    return "";
  const e = window.localStorage.getItem(a), t = (r = (s = e == null ? void 0 : e.substring(0, 20)) == null ? void 0 : s.match(/.{1,4}/g)) == null ? void 0 : r.join("-"), o = e == null ? void 0 : e.substring(20);
  return `${t}-${o}`;
};
export {
  g as LINKED_SESSION_IDS,
  u as MIXPANEL_EVENTS,
  S as NEW_SESSION,
  a as SESSION_ID,
  l as SESSION_TIMESTAMP,
  J as appendUTMParams,
  F as checkDisableMixpanel,
  k as generateNewSession,
  O as generateNewSessionFlag,
  D as generateNewSessionId,
  P as generateSessionTimestamp,
  K as getFormattedSessionId,
  R as getLinkedSessionIds,
  M as getNewSessionFlag,
  b as getOrCreateSessionId,
  v as getSessionId,
  $ as getSessionTimestamp,
  N as mixpanelInit,
  j as removeNewSessionFlag,
  L as removeSessionId,
  G as removeSessionTimestamp,
  H as setLinkedSessionId,
  x as setSessionId,
  C as trackPageView,
  T as trackUserId
};
